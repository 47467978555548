import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}
const _hoisted_2 = { class: "text-dark order-2 order-md-1" }
const _hoisted_3 = { class: "text-muted fw-bold me-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex-column flex-md-row align-items-center justify-content-between", {
        'container-fluid': _ctx.footerWidthFluid,
        'container-xxl': !_ctx.footerWidthFluid,
      }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getYear()) + " © sms.atscapitalproperties.com", 1)
      ])
    ], 2)
  ]))
}