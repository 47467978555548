
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {

    const getYear = () =>{
      const d = new Date();
      let year = d.getFullYear();
      return year;
    }
    return {
      footerWidthFluid,
      getYear
    };
  },


});
