const AdminMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
      },
      // {
      //   heading: "Plans",
      //   route: "/plan",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
      // {
      //   heading: "Add Plan",
      //   route: "/plan/create",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //   fontIcon: "bi-layers",
      // },
    ],
  },
  {
    heading: "Admin Navigation",
    route: "/menu",
  },
  {
    pages: [
      {
        heading: "User",
        route: "/admin/user",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Plan",
        route: "/admin/plan",
        svgIcon: "media/icons/duotune/abstract/abs027.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Coupon",
        route: "/admin/coupon",
        svgIcon: "media/icons/duotune/ecommerce/ecm003.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Report",
        route: "/user/report",
        svgIcon: "media/icons/duotune/files/fil008.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default AdminMenuConfig;
