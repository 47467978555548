
import { defineComponent, onMounted, ref } from "vue";
// import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { io } from "socket.io-client";

export default defineComponent({
  name: "topbar",
  components: {
    // KTSearch,
    KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const loginData = ref<any>(false);
    const formData = ref<any>({});
    const remainingText = ref(null);
    onMounted(() => {
      // console.log('test ');
      const data = localStorage.getItem("userdata");
      if (data) {
        // JSON.parse(localStorage.getItem('userdata'));
        var allData = JSON.parse("" + data + "");
        // console.log('all Data ', allData.type);
        loginData.value = allData;
      }

      getRemaingCount();

      const socket = io(`${process.env.VUE_APP_SOKET_URL}`, {
        transports: ["websocket"],
      });
      const userData = localStorage.getItem("userdata");
      socket.on("messageCount", (msg) => {
        console.log(msg);
        if (userData) {
          var objUser = JSON.parse(userData);
          if (objUser._id == msg) {
            getRemaingCount();
          }
        }
      });
    });

    const getRemaingCount = () => {
      var request = {
        url: `user/remaining-text`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          remainingText.value = data.data.number_texts;
          console.log(remainingText.value);
        }
      });
    };

    return {
      loginData,
      formData,
      getRemaingCount,
      remainingText,
    };
  },
});
