
import { defineComponent, computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import useEmitter from '@/composables/useEmitter'
export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const emitter = useEmitter()
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const dataAllGet = ref("");

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    // const countries = {
    //   en: {
    //     flag: "media/flags/united-states.svg",
    //     name: "English",
    //   },
    //   es: {
    //     flag: "media/flags/spain.svg",
    //     name: "Spanish",
    //   },
    //   de: {
    //     flag: "media/flags/germany.svg",
    //     name: "German",
    //   },
    //   ja: {
    //     flag: "media/flags/japan.svg",
    //     name: "Japanese",
    //   },
    //   fr: {
    //     flag: "media/flags/france.svg",
    //     name: "French",
    //   },
    // };

    onMounted(() => {

      emitter.on("toggle-sidebar", isOpen => {
          console.log(isOpen);
        //this.isOpen = isOpen;
        const userData = localStorage.getItem("userdata");
        var allData = JSON.parse("" + userData + "");
        dataAllGet.value = allData;
      });
      const userData = localStorage.getItem("userdata");
      var allData = JSON.parse("" + userData + "");
      dataAllGet.value = allData;
      // console.log('fdfs',allData);
    });

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    // const currentLangugeLocale = computed(() => {
    //   return countries[i18n.locale.value];
    // });

    return {
      signOut,
      setLang,
      dataAllGet,
      currentLanguage,
      // currentLangugeLocale,
      // countries,
    };
  },
});
