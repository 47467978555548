const MyMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
      },
      // {
      //   heading: "Plans",
      //   route: "/plan",
      //   svgIcon: "media/icons/duotune/art/art002.svg",
      //   fontIcon: "bi-app-indicator",
      // },
      // {
      //   heading: "Add Plan",
      //   route: "/plan/create",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //   fontIcon: "bi-layers",
      // },
    ],
  },
  {
    heading: "User Navigation",
    route: "/menu",
  },
  {
    pages: [
      {
        heading: "Number",
        route: "/user/number",
        svgIcon: "media/icons/duotune/files/fil008.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "General Inbox",
        route: "/user/inbox",
        svgIcon: "media/icons/duotune/general/gen016.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "",
    route: "/user/message",
    pages: [
      {
        sectionTitle: "Message",
        route: "/customers",
        svgIcon: "media/icons/duotune/general/gen016.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Single Message",
            route: "/user/single/message",
          },
          {
            heading: "Bulk Message",
            route: "/user/bulk/message",
          },
        ],
      },
    ],
  },
  {
    heading: "",
    route: "/user/contact",
    pages: [
      {
        sectionTitle: "Contact",
        route: "/customers",
        svgIcon: "media/icons/duotune/communication/com005.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Import Contacts",
            route: "/user/import/contact",
          },
          {
            heading: "Contact Group",
            route: "/user/group/contact",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        heading: "Opt Out",
        route: "/user/optOUt",
        svgIcon: "media/icons/duotune/arrows/arr015.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Template Message",
        route: "/user/template/message",
        svgIcon: "media/icons/duotune/communication/com003.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Tags",
        route: "/user/tags",
        svgIcon: "media/icons/duotune/ecommerce/ecm003.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Autoresponder Keywords",
        route: "/user/autoresponder/keywords",
        svgIcon: "media/icons/duotune/abstract/abs040.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Manage Campaign",
        route: "/user/manage/campaign",
        svgIcon: "media/icons/duotune/abstract/abs043.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Report",
        route: "/user/report",
        svgIcon: "media/icons/duotune/files/fil008.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default MyMenuConfig;
